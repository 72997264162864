import React from "react"
import { graphql } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"

const Container = styled.div`
  height: calc(100vh - var(--footer-height));
  padding-top: var(--nav-height);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`

const StyledAniLink = styled(AniLink)`
  margin-top: 3rem;
`;

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Bui-Pędowska Architekci - dziękujemy za wysłanie wiadomości" />
      <Container>
        <h2>Dziękujemy za wysłanie wiadomości!</h2>
        <p>Odpowiemy na Twojego maila najszybciej jak to będzie możliwe.</p>
        <StyledAniLink
          cover
          direction="right"
          bg="#fdfdfe"
          to="/"
          className="button"
        >
          <span>Przejdź do strony głównej</span>
        </StyledAniLink>
      </Container>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
